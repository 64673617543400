<template>
  <div id="platform">
    <!-- desktop style -->
    <div class="pa-10 d-none d-md-block">
      <div class="d-flex align-center justify-center text-h2 font-weight-bold py-8 montserrat-font title-color">Platform Details</div>
      <v-row no-gutters>
        <v-col cols="8" lg="7" class="rounded-tl-xxl" style="background-color: rgb(254, 246, 232)">
          <div class="text-center pa-6">
            <p class="text-h5 text-lg-h4 text-xl-h2 font-weight-bold montserrat-font title-color">BACK-END</p>
            <div class="montserrat-font text-lg-h6 text-xl-h4">The heavy lifting will</div>
            <div class="montserrat-font text-lg-h6 text-xl-h4">be handled by mApe</div>
          </div>
        </v-col>
        <v-col cols="4" lg="5" class="rounded-tr-xxl" style="background-color: #fcb017">
          <div class="text-center white--text pa-6">
            <p class="text-h5 text-lg-h4 text-xl-h2 font-weight-bold montserrat-font">Business Values</p>
            <div class="montserrat-font text-lg-h6 text-xl-h4">On point solution with clear-cut</div>
            <div class="montserrat-font text-lg-h6 text-xl-h4">reports that you will see</div>
          </div>
        </v-col>
      </v-row>
      <v-divider dark></v-divider>
      <v-responsive :aspect-ratio="100 / 30">
        <v-row no-gutters class="fill-height">
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="8" lg="7" class="rounded-bl-xxl" style="background-color: rgb(254, 246, 232)">
            <div class="fill-height d-flex align-center justify-end">
              <!-- <v-spacer></v-spacer> -->
              <div class="fill-height d-flex flex-column justify-space-around pl-12">
                <v-chip class="my-2 pl-0 pr-6 elevation-2" input-value="false" color="#F5F5F5" pill x-large v-for="(item, idx) in backEndItems" :key="idx">
                  <v-btn class="mr-2" elevation="1" color="#F5F5F5" fab dark large left>
                    <img width="40" :src="item.img" />
                  </v-btn>
                  <!-- {{ item.desc }} -->
                  <div class="text-body-1 text-lg-h5 text-xl-h3 montserrat-font" v-text="item.desc"></div>
                </v-chip>
              </div>
              <v-icon size="68" color="rgba(255, 220, 163)">mdi-arrow-right-bold</v-icon>
              <!-- <v-img max-width="35%" max-height="35%" src="@/assets/img/mape/svg/engine-no-arrow.svg" contain></v-img> -->
              <v-img max-width="40%" max-height="75%" src="@/assets/img/mape/svg/engine-no-arrow.svg" contain></v-img>
              <v-icon size="68" color="rgba(255, 220, 163)">mdi-arrow-right-bold</v-icon>
            </div>
          </v-col>
          <v-col cols="4" lg="5" class="rounded-br-xxl" style="background-color: #fcb017">
            <div class="fill-height d-flex flex-column justify-space-around px-8 px-lg-12 px-xl-16">
              <div class="d-flex align-center justify-start white--text" v-for="(item, idx) in businessItems" :key="idx">
                <img width="40" :src="item.img" />
                <div class="text-body-1 text-lg-h5 text-xl-h3 ml-6 montserrat-font" v-text="item.desc"></div>
                <!-- <v-spacer></v-spacer> -->
              </div>
            </div>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
        </v-row>
      </v-responsive>
    </div>

    <!-- Mobile and Pad style -->
    <div class="d-block d-md-none px-4 py-10">
      <p class="d-flex align-start justify-start flex-column text-h4 text-sm-h3 font-weight-bold montserrat-font title-color">Platform Details</p>
      <div class="text-center pa-6 rounded-t-xl" style="background-color: rgb(254, 246, 232)">
        <p class="text-h5 font-weight-bold montserrat-font title-color">BACK-END</p>
        <div class="montserrat-font">The heavy lifting will be handled by mApe</div>
      </div>

      <v-divider dark></v-divider>

      <div class="px-4" style="background-color: rgb(254, 246, 232)">
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="pt-4 px-3" v-for="(item, idx) in backEndItems" :key="idx">
            <v-chip class="pl-0 pr-6 d-block elevation-2" input-value="false" color="#F5F5F5" pill large>
              <v-btn class="mr-2" elevation="1" color="#F5F5F5" fab dark left>
                <img width="40" :src="item.img" />
              </v-btn>
              <span class="text-body-1 text-sm-body-1 text-md-h6 text-wrap montserrat-font">{{ item.desc }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex align-center justify-center flex-column" style="background-color: rgb(254, 246, 232)">
        <v-icon size="68" color="rgba(255, 220, 163)">mdi-arrow-down-bold</v-icon>
        <!-- <img width="70%" src="@/assets/img/mape/svg/engine-no-arrow.svg" cover /> -->
        <v-img width="70%" max-width="400" src="@/assets/img/mape/svg/engine-no-arrow.svg" contain></v-img>
        <v-icon size="68" color="rgba(255, 220, 163)">mdi-arrow-down-bold</v-icon>
      </div>

      <div class="text-center white--text pa-6" style="background-color: #fcb017">
        <p class="text-h6 font-weight-bold montserrat-font">Business Values</p>
        <div class="montserrat-font">On point solution with clear-cut reports that you will see</div>
      </div>

      <v-divider dark></v-divider>

      <div class="px-3 pt-2 rounded-b-xl" style="background-color: #fcb017">
        <v-row no-gutters class="d-flex align-center justify-center">
          <v-col cols="12" sm="6" class="px-3" v-for="(item, idx) in businessItems" :key="idx">
            <div class="d-flex align-center justify-start white--text py-2">
              <img width="40" :src="item.img" class="ml-1" />
              <div class="text-body-1 text-sm-body-1 text-md-h6 ml-6 montserrat-font" v-text="item.desc"></div>
              <!-- <v-spacer></v-spacer> -->
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    backEndItems: [
      {
        img: require("@/assets/img/mape/svg/marketing-platforms.svg"),
        desc: "Marketing Platforms",
      },
      {
        img: require("@/assets/img/mape/svg/marketing-analytics.svg"),
        desc: "Marketing Analytics",
      },
      {
        img: require("@/assets/img/mape/svg/sales-data.svg"),
        desc: "Sales Data",
      },
      {
        img: require("@/assets/img/mape/svg/marketing-activities.svg"),
        desc: "Marketing Activities",
      },
    ],
    businessItems: [
      {
        img: require("@/assets/img/mape/svg/know-your-roi.svg"),
        desc: "Know Your ROI",
      },
      {
        img: require("@/assets/img/mape/svg/which-channels-are-best.svg"),
        desc: "Which Channels Are Best",
      },
      {
        img: require("@/assets/img/mape/svg/budget-planning.svg"),
        desc: "Budget Planning",
      },
      {
        img: require("@/assets/img/mape/svg/campaign-optimisation.svg"),
        desc: "Campaign Optimisation",
      },
    ],
  }),
};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}
.title-color {
  color: rgb(0, 0, 0, 0.6) !important;
}
</style>
