<template>
  <div class="px-4 px-md-10 unique-container">
    <div class="d-md-none">
      <div class="d-flex" style="position: relative">
        <div class="pb-6">
          <div class="text-h4 text-sm-h3 text-md-h2 white--text font-weight-bold pt-16 montserrat-font">
            <span class="font-weight-bold title-color">What</span><span class="font-weight-bold" style="color: #fcb017"> makes</span>
          </div>
          <div class="text-h4 text-sm-h3 text-md-h2 font-weight-bold pt-1 montserrat-font" style="color: #fcb017">us unique</div>
        </div>
        <!-- <v-spacer></v-spacer> -->
        <v-img class="d-block" style="position: absolute; bottom: -30px; right: 0" width="40%" src="@/assets/img/mape/svg/logo-glass.svg" cover></v-img>
      </div>
      <v-sheet color="primary" class="rounded-xxl text-center img-container">
        <v-list class="transparent">
          <v-list-item v-for="(item, idx) in items" :key="idx">
            <v-list-item-avatar size="90" tile>
              <v-img :src="item.img" height="72" contain></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 text-sm-h4 text-md-h3 font-weight-bold white--text text-left montserrat-font">
                {{ item.title }}
              </v-list-item-title>
              <div class="montserrat-font white--text text-left" v-text="item.desc"></div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn class="my-5" width="200" elevation="2" @click="$vuetify.goTo('#contact-us')" large rounded dark>LEARN MORE </v-btn>
      </v-sheet>
    </div>

    <v-sheet color="primary" class="rounded-xxl d-none d-md-block img-container">
      <v-row class="d-flex align-center justify-center" no-gutters>
        <v-col cols="12" md="5" align-self="stretch" class="pl-8" style="position: relative">
          <div class="text-md-h2 font-weight-bold pt-16 mt-16 montserrat-font title-color"><span>What</span><span class="white--text"> makes</span></div>
          <div class="text-md-h2 white--text font-weight-bold pt-1 montserrat-font" style="color: #fcb017">us unique</div>
          <!-- <v-btn class="mt-8" width="200" elevation="2" @click="$vuetify.goTo('#contact-us')" large rounded dark>LEARN MORE </v-btn> -->
          <v-img max-height="400" width="300" src="@/assets/img/mape/svg/logo-glass.svg" style="position: absolute; left: -15px; bottom: -20px" cover></v-img>
        </v-col>
        <v-col cols="12" md="7">
          <v-row class="my-8" no-gutters>
            <v-col v-for="(item, idx) in items" cols="6" :key="idx" class="pa-6">
              <v-img width="72" :src="item.img" contain></v-img>
              <div class="text-h4 font-weight-bold white--text montserrat-font" v-text="item.title"></div>
              <div class="montserrat-font white--text text-h6" v-text="item.desc"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        img: require("@/assets/img/mape/svg/the-first.svg"),
        title: "The First",
        desc: "We are the first AI unified marketing measurement platform",
      },
      {
        img: require("@/assets/img/mape/svg/on-point.svg"),
        title: "On Point",
        desc: "We target our solution to what your business needs",
      },
      {
        img: require("@/assets/img/mape/svg/innovative.svg"),
        title: "Innovative",
        desc: "New ways to deliver solutions to marketers",
      },
      {
        img: require("@/assets/img/mape/svg/clear-cut.svg"),
        title: "Clear-Cut",
        desc: "Suggested insights with actionable steps to follow to improve revenue and profitability",
      },
      {
        img: require("@/assets/img/mape/svg/low-client-effort.svg"),
        title: "Low Client Effort",
        desc: "We do all the heavy lifting for our clients",
      },
      {
        img: require("@/assets/img/mape/svg/quantum-leap.svg"),
        title: "Quantum Leap",
        desc: "Enable you to shortcut the journey to the final level of marketing analytics",
      },
    ],
  }),
};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}

.title-color {
  color: rgb(0, 0, 0, 0.6) !important;
}
.unique-container {
  position: relative;
  z-index: 2;
}
.img-container {
  background-image: url(@/assets/img/mape/svg/us-unique-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
