import http from "@/utils/http/index.js";

export default {
  uploadTblData: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/etl/task/upload-tbl-data", param, headers);
  },
  uploadBaseModelData: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/etl/create-model/upload-base-model-data", param, headers);
  },
  uploadResponseData: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/etl/create-model/upload-response-data", param, headers);
  },
  etlStar: (param) => {
    return http.$p("/etl/task/start", param);
  },
  createItemsModel: (param) => {
    return http.$p("/etl/create-model/create-items-model", param);
  },
  modelNameList: (param) => {
    return http.$p("/etl/create-model/model-name-list", param);
  },
  uploadReportSource: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/etl/transform/upload", param, headers);
  },
  uploadForecast: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/etl/transform/upload-forecast", param, headers);
  },
};
