import http from "@/utils/http/index.js";

export default {
  barChart: (param) => {
    return http.$p("/dashboard/bar-chart", param);
  },
  lineChart: (param) => {
    return http.$p("/dashboard/line-chart", param);
  },
  dpTable: (param) => {
    return http.$p("/dashboard/dp-table", param);
  },
};
