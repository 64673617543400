<template>
  <v-sheet dark>
    <v-row class="py-5" no-gutters align="center">
      <v-col cols="12" md="4" class="my-5 text-center">
        <img height="100" src="@/assets/img/mape/svg/logo.svg" />
        <span class="montserrat-font d-block text-caption">©{{ fullYear }} marketingApe. All rights reserved.</span>
      </v-col>
      <v-col cols="12" md="8" class="text-center">
        <v-row no-gutters>
          <v-col cols="12" sm="6" lg="3" class="my-5 text-md-left">
            <div class="px-4 py-2 text-h5 white--text montserrat-font">Site Map</div>
            <div><v-btn @click="$vuetify.goTo('#about-us')" text dark>About us</v-btn></div>
            <div><v-btn @click="$vuetify.goTo('#platform')" text dark>Platform</v-btn></div>
            <div><v-btn @click="$vuetify.goTo('#contact-us')" text dark>Contact</v-btn></div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="my-5 text-md-left">
            <div class="px-4 py-2 text-h5 white--text montserrat-font">Connect</div>
            <div>
              <v-btn text dark> <v-icon class="mr-2">mdi-web</v-icon><span class="connect-link-text text-center text-md-start">www.mApe.ai</span> </v-btn>
            </div>
            <div>
              <v-btn text dark>
                <v-icon class="mr-2">mdi-email-outline</v-icon><span class="connect-link-text text-center text-md-start">contact@mape.ai</span>
              </v-btn>
            </div>
            <div>
              <v-btn text dark href="https://www.linkedin.com/company/marketingape" target="_blank">
                <v-icon class="mr-2">mdi-linkedin</v-icon><span class="connect-link-text text-center text-md-start">marketingApe</span>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="3" class="my-5 text-md-left">
            <div class="px-4 py-2 text-h5 white--text montserrat-font">Find us</div>
            <div>
              <v-btn text dark href="https://maps.app.goo.gl/CPTyNuJPj6dqSk9C7" target="_blank">
                <v-icon class="mr-2">mdi-map-marker-radius-outline</v-icon>marketingApe (Thailand)
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="auto" class="my-5 text-md-left">
            <div class="px-4 py-2 text-h5 white--text montserrat-font">Legal</div>
            <div>
              <v-btn text dark href="/privacyPolicy" target="_blank"> <v-icon class="mr-2">mdi-shield-lock-outline</v-icon> Privacy Policy </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "FooterView",
  data() {
    return {
      fullYear: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}
.connect-link-text {
  width: 126px;
}
</style>
